import React, { useEffect } from "react"
import { navigate } from "gatsby"

import "@stripe/stripe-js"
import NewCheckout from "../../components/v2/checkout/Checkout"
import NewCheckoutAdyen from "../../components/v2/checkout/CheckoutAdyen"
import SEO from "../../components/seo"
import { useStore } from "../../context/NewStoreContext"
import { hasAdyenEnabled } from "../../components/v2/utils/cart/hasAdyenEnabled"

const CheckoutPage = () => {
  const store = useStore()

  useEffect(() => {
    if (store.cart?.id && !store.cart?.items?.length) {
      navigate("/")
      return
    }
  }, [])

  return store?.cart?.id ? (
    <>
      <SEO title="Checkout" canonicalUrl="/checkout" breadcrumbsList={[
        {
          "@type": "ListItem",
          position: 1,
          name: "Home",
          item: "https://teklafabrics.com",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: "Checkout",
          item: "https://teklafabrics.com/checkout",
        }
      ]} />
      {hasAdyenEnabled(store.cart) ? <NewCheckoutAdyen /> : <NewCheckout />}
    </>
  ) : null
}

export default CheckoutPage
